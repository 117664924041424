<template>
<v-container>
      <v-layout row wrap class="mt-5 mb-5">
        <v-flex xs12>
            <v-toolbar flat color="white">
                <v-toolbar-title>Make your installation appointment</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
            <v-card flat>
                <v-card-text>
                    Choose a date for the installation of box
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex xs6>
            <v-sheet
              tile
              height="54"
              class="d-flex"
            >
              <v-btn
                class="ma-2"
                @click="$refs.calendar.prev()"
                small
              >
                <v-icon>mdi-chevron-left</v-icon>
                    Previous month
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                @click="$refs.calendar.next()"
                small
              >
                <v-icon>mdi-chevron-right</v-icon>
                Next month
              </v-btn>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                ref="calendar"
                v-model="value"
                :weekdays="weekday"
                :type="type"
                :events="events"
                :event-overlap-mode="'column'"
                :event-overlap-threshold="30"
                :event-color="getEventColor"
                @change="getEvents"
                @click:event="getDate($event)"
              ></v-calendar>
            </v-sheet>
        </v-flex>
        <v-flex xs5 class="ml-5">
          <v-card
            outlined
          >
          <v-card-title>Legend</v-card-title>
          <v-card-text>
              <b>AM</b> - morning (between 8 a.m. and 1 p.m.) <br>
              <b>PM</b> - afternoon (between 1 pm and 7 pm)
          </v-card-text>
          </v-card>
          <v-card
            outlined
            class="mt-4"
          >
          <v-card-title>You have selected the next date</v-card-title>
          <v-card-text>
            <b>{{currentEventName}}</b> {{currentDate | formatDate}}
          </v-card-text>
          </v-card>
          <v-card
            outlined
            class="mt-4"
          >
          <v-card-title>Notice to installer (optional)</v-card-title>
          <v-card-text>
        <v-textarea
          name="input-7-1"
          label="Text"
          hint="Content"
        ></v-textarea>
          </v-card-text>
          </v-card>
          <div class="text-right">
          <v-btn
            class="mr-4 mt-4"
            color="primary"
            @click="$router.push({name:'requestDetails', params: {id: id, dateIn: currentDate}})"
            >
              Save
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
</v-container>
</template>
<script>
import moment from 'moment'

export default {
  data: () => ({
    type: 'month',
    currentDate: '',
    types: ['month', 'week', 'day', '4day'],
    mode: 'stack',
    modes: ['stack', 'column'],
    weekday: [1, 2, 3, 4, 5, 6, 0],
    weekdays: [
      { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] }
    ],
    value: '',
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    currentEventName: ''
  }),
  methods: {
    getDate ($event) {
      console.log($event)
      this.currentDate = $event.event.start
      this.currentEventName = $event.event.name + ' - '
    },
    getEvents () {
      const events = []
      const min = moment(new Date('2020-11-04T08:00:00')).format('YYY-MM-DD hh:mm')
      const max = moment(new Date('2020-11-04T13:00:00')).format('YYY-MM-DD hh:mm')
      const minBis = moment(new Date('2020-11-04T14:00:00')).format('YYY-MM-DD hh:mm')
      const maxBis = moment(new Date('2020-11-04T19:00:00')).format('YYY-MM-DD hh:mm')
      const allDay = true
      /* events.push({
        name: 'MO',
        color: 'green',
        start: min,
        end: max,
        timed: true
      })
      events.push({
        name: 'AF',
        color: 'green',
        start: minBis,
        end: maxBis,
        timed: false
      }) */
      // let startDate = new Date()
      let start = moment().toDate()
      let end = moment().toDate()
      for (let index = 0; index < 69; index++) {
        const base = start
        start = moment(base).add(1, 'days').toDate()
        end = moment(base).add(1, 'days').toDate()
        const dayNumber = moment(start).weekday()
        if (dayNumber > 0 && dayNumber < 6) {
          let color = 'green'
          if (index % 3 === 0) {
            color = 'red'
          }
          events.push({
            name: 'AM',
            color: color,
            start: start,
            end: end,
            timed: false
          })
          start = moment(base).add(1, 'days').toDate()
          end = moment(base).add(1, 'days').toDate()
          if (index % 2 === 0) {
            color = 'red'
          }
          events.push({
            name: 'PM',
            color: color,
            start: start,
            end: end,
            timed: false
          })
        }
        // start.add
        // startDate = startDate.setDate(startDate.getDate() + 1)
      }
      // moment().add(1, 'days').calendar()
      // moment().format('LLL')
      this.events = events
    },
    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    }
  },
  filters: {
    formatDate (val) {
      if (val !== '') {
        return moment(val).format('LL')
      } else {
        return 'No'
      }
    }
  },
  props: ['id']
}
</script>
